import Card from '@pretto/bricks/components/cards/Card'
import Divider from '@pretto/bricks/components/dividers/Divider'
import Icon from '@pretto/bricks/components/iconography/Icon'
import { g } from '@pretto/bricks/components/layout'
import Content from '@pretto/bricks/components/layout/Content'
import Heading from '@pretto/bricks/components/typography/Heading'
import Text from '@pretto/bricks/components/typography/Text'
import RatesGraphLegend from '@pretto/bricks/website/rates/components/RatesGraphLegend'
import { Column } from '@pretto/bricks/website/rates/components/RatesGrid/styles'
import AuthorSignature from '@pretto/bricks/website/shared/components/AuthorSignature'
import Link from '@pretto/bricks/website/utility/Link'

import RatesSelectController from '@pretto/website/src/components/Rates/RatesSelectController'
import { Author } from '@pretto/website/src/templates/blocs/types/Author.type'
import { ControllerProps } from '@pretto/website/src/templates/blocs/types/ControllerProps.type'

import styled from 'styled-components'

type EvolutionGraph = {
  component: React.FC
  props: (props: ControllerProps) => void
}

type RatesAnalysisProps = {
  defaultDuration?: number
  evolutionGraph: EvolutionGraph
  author: Author
}

export const RatesAnalysis: React.FC<RatesAnalysisProps> = ({ author, defaultDuration, evolutionGraph }) => (
  <>
    <Column>
      <Title>
        <Heading size="large">{author.ratesSettings.title}</Heading>
      </Title>

      <IntroductionExcerpt>« {author.ratesSettings.news.excerpt} »</IntroductionExcerpt>

      <Divider variant="neutral-1-20" />

      <Signature>
        <AuthorSignature {...author.ratesAuthor} format="small" noShare />
      </Signature>

      <Text variant="primary-1">
        <Link href={author.ratesSettings.news.uri}>
          {author.ratesSettings.anchor}
          <IntroductionReadMoreIcon>
            <Icon name="arrow-right" variant="primary-1" />
          </IntroductionReadMoreIcon>
        </Link>
      </Text>
    </Column>

    <Column>
      <Card format="line" overflowVisible variant="neutral-1-20">
        <Content>
          <RatesSelectController defaultDuration={defaultDuration}>
            {props => {
              const { graph, legend, select: Select } = props(evolutionGraph)

              return (
                <>
                  <Select>{({ duration }: ControllerProps) => <>Évolution des taux pour un prêt {duration}.</>}</Select>
                  <Graph>{graph}</Graph>
                  <RatesGraphLegend items={legend} />
                </>
              )
            }}
          </RatesSelectController>
        </Content>
      </Card>
    </Column>
  </>
)

const Graph = styled.div`
  margin: ${g(4)} 0;
`

const IntroductionExcerpt = styled.p`
  margin-bottom: ${g(2)};
`

const IntroductionReadMoreIcon = styled.span`
  margin-left: ${g(1)};
  display: inline-block;
  position: relative;
  top: ${g(0.25)};
`

const Signature = styled.div`
  align-items: center;
  display: flex;
  margin: ${g(3)} 0;
`

const Title = styled.div`
  display: flex;
  margin-bottom: ${g(3)};
`
